import styled from 'styled-components';

export const Wrapper = styled.div`
	background-color: #F9F9F3;
	display: flex;
	height: 400px;
	border-radius: 10px;

	.header-nav {
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
		gap: 10px;
		margin: 29px 0px 0px 27px;

		.nav-link {
			
            box-shadow: 0px 1px 2px #0000000F;
            border-radius: 5px;
    /* opacity: 0.55; */
             width: 198px;
              height: 55px;
             padding: 20px;
			 background-color: var(--tab-link-bgColor);
			font-size: 13px;
			line-height: 20px;
			font-weight: 500;
			color: var(--tab-link-color);
			display: flex;
			flex-direction: row;
			align-items: center;
		//	justify-content: end;
		//	flex-direction: column;
			padding-bottom: 12px;
			cursor: pointer;
			&:hover,
			&.active {
				background-color: var(--tab-link-bgColor-hover);
				color: var(--tab-link-color-hover);
				border: 1px solid #C46230;
				border-bottom: 1px solid #dfdfdf;
				/* svg {
					.path-stroke {
						stroke: #002970;
					}
					.path-color {
						fill: #002970;
					}
					.path-stroke-white {
						stroke: #fff;
					}
					#Path_197,
					#Line_2,
					#Line_5,
					
					#Ellipse_42{
						stroke: #002970;
					}
				
				
				
			} */
			
			/* &.active .pic,
			&:hover .pic{
				svg {
					#Subtraction_4,
					#Path_550{
						stroke:#002970
					}
				}
			} */
		}
		.pic{
			padding-right:27.3px;

		}
	}
	}
	.slick-slider {
		width: 100%;
	}
	
	.slick-track{
		display: flex;
		gap: 5px;
		margin-left: 0px;
		flex-direction: column;
	}

	.slick-prev, .slick-next{
		width: 40px;
		height: 40px;
		z-index: 1;
		
	}

	.slick-next {
    right: 2px;
}
.slick-prev {
    left: 2px;
 
}


.slick-dots li button:before{
	color: var(--slick-dots-color);
	opacity: 1;
}
.slick-dots li.slick-active button:before{
	color: var(--slick-active-color);
}
	.slick-prev:before, .slick-next:before {
  
    color: var(--slick-active-color);
	font-size: 25px;
    
}
`;
