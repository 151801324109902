import styled from 'styled-components';

export const Wrapper = styled.div`
	background-image: url('../../assets/images/bharatBanner.png');
	background-position-x: 53%;
    background-position-y: 100%;
	background-repeat: no-repeat;
	background-color: var(--tab-content-bgColo);
	margin: -30px;
	padding-left: 47px;
	height: calc(100% + 60px); 

	.banner-text {
		padding: 32px 0 20px 0px;

		h1 {
			font-size: 30px;
			line-height: 42px;
			font-weight: bold;
			color: #141414;
			text-transform: uppercase;
			text-align: center;
		}
		.flat-text {
			font-size: 15px;
			color: #141414;
			font-weight: 500;
			margin-bottom: 13px;
			text-align: center;
		}
		i {
			margin-top: 82px;
			display: block;
			font-style: normal;
			font-size: 10px;
			line-height: 16px;
		}
	}
`;
