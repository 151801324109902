import React from 'react';
import { Wrapper } from './style';
import img from '../../assets/images/banner.png'


export default function OfferBanner() {
	return (
		<Wrapper className='d-m-none' style={{backgroundImage:`url(${img})`}}>
			<div className="banner-text">
				<h1>
				Recharges, Bill<br/>Payments 
				</h1>
				<div className="flat-text">The best B2B and  Banking<br/> services provider</div>
			</div>
		</Wrapper>
	);
}