import user from '../../assets/images/user-dil-red.svg';
import star from '../../assets/images/start.svg';
import bag from '../../assets/images/bag.svg';

function Component() {
	return (
		<div className="footerContactwrapper">
			<div className="container">
				<div className="footer-contact-wrapper flex justify-center gap-20">
					<div className="box">
						<div className="img center">
							<img src={bag} alt="bag" />
						</div>
						<h2>Professional</h2>
						<p>With over 10 years of experience, we deliver security and reliability</p>
					</div>
					<div className="box">
						<div className="img center">
							<img src={user} alt="" />
						</div>
						<h2>Popular</h2>
						<p>New customers join every 2 minutes</p>
					</div>
					<div className="box">
						<div className="img center">
							<img src={star} alt="bag" />
						</div>
						<h2>Efficient</h2>
						<p>We guarantee 100% satisfaction for every transaction</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Component;
