import { Wrapper } from './style';

import img from '../../assets/images/growBussiness.png'

function Component() {
	return (
		<Wrapper>
			<div className="container flex justify-between">
				<div className="img self-end">
					<img className="w-100" src={img} alt="img" />
				</div>
				<div className="about-text self-center mb20">
					<h2 className="heading-2">
					Recharges & Bill Payments
					</h2>
					<h2 style={{fontSize:"15px"}}>Lightning fast payments, exciting rewards and seamless transactions on every recharge & bill payment.</h2>
					<p>
					We provide digital platform to process transaction to our client. We offer better success rates, relationships and business networks that will help further grow and augment your business volumes. We facilitate seamless transactions with a multitude of entrepreneurs and businesses, and aim to sustain and strengthen, develop and innovate digital payment services in India.
					</p>
				</div>
			</div>
		</Wrapper>
	);
}

export default Component;
