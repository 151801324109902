import React from 'react';
import './Contactus.css';
import location from '../../assets/images/location.png';
import envelop from '../../assets/images/envelop.png';
import mobilebutton from '../../assets/images/mobilebutton.png';
function Contactus() {
	return (
		<div className="content-wrapper">
			<div className="heading">
				<h2 className="m-auto">Contact Us</h2>
			</div>
			<div className="contactuswrapper">
				<div className="containers">
					<div className="heading-contact text-center">
						<label className="m-auto">
							We’d Love To <strong>Help You</strong>
						</label>
					</div>
					<div className="consumer-top  consumer-middle flex justify-center gap-100">
						<div className="contactus">
							<iframe
src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d56025.99592719372!2d77.06880806617667!3d28.6410067993103!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sGROUND%20FLOOR%2C%20PLOTNO.%205%2FA%2C%20SHOP%20N0.12%2C%20NEAR%20KAILASH%20PARK%20%2C%20%20%09%09%09%09%09%09%09%09%09%09KIRTI%20NAGAR%2C%20NAJAFGARH%20ROAD%2CNEW%20DELHI%2CNEW%20DELHI%2CDELHI%2C110015!5e0!3m2!1sen!2sin!4v1698040233048!5m2!1sen!2sin"								width="100%"
								height="100%"
								allowfullscreen=""
								loading="lazy"
								referrerpolicy="no-referrer-when-downgrade"
								title='map'
							></iframe>
							<div className="contactusdetail">
								<div className="contactaddress">
									<div className="address">
										<label>Registered Address</label>
									</div>
									<div className="address">
										<img src={location} alt='location' />
										<p>GROUND FLOOR, PLOTNO. 5/A, SHOP N0.12, NEAR KAILASH PARK ,<br/> 
										KIRTI NAGAR, NAJAFGARH ROAD,NEW DELHI,NEW DELHI,DELHI,110015</p>
									</div>
								</div>
								<div className="contactaddress">
									<div className="address">
										<label>Contact Us</label>
									</div>
									<div className="address">
										<img src={envelop} alt='envelop' />
										<p>
											<strong>Email Id :</strong>office.swagarerpises@gmail.com
										</p>
									</div>
									<div className="address">
										<img src={mobilebutton} alt='mobilebutton' />
										<p>
											<strong>Phone No :</strong>8859411482
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Contactus;
