import styled from 'styled-components';

export const Wrapper = styled.div`
	max-width: 1480px;
    margin: 0 auto;
	padding: 50px 50px 0;
	margin-top: 20px;
	
	overflow: hidden;
	.img {
		flex: 0 1 500px;
		img {
			margin-bottom: -27px;
		}
	}
	.about-text {
		flex: 0 1 600px;

		h2 {
			margin-bottom: 14px;
			text-align: justify;
		}
		p {
			font-size: 20px;
			text-align: justify;
			color: #000000;
		}
	}
	@media only screen and (max-width: 767px) {
		padding: 20px 20px 0;
		.container {
			flex-direction: column-reverse;

			& > div {
				margin: 0 auto 0;
				flex: 0 1 auto;
			}
		}
	}
`;
